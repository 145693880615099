import isEqual from 'lodash/isEqual';
import { useRecoilCallback } from 'recoil';
import { layoutState, useRecoil, LayoutStateProps } from '@src/recoilAtoms';

type Params = Partial<Record<keyof LayoutStateProps, boolean>>;

interface Queue {
  params: Params;
  resolve: () => void;
}

const useGlobalLayout = () => {
  const { recoilState, setRecoilState } = useRecoil(layoutState);

  // multiple components might called setGlobalLayout in useEffect simultaneously
  // to avoid conflicts, the update function is queued to ensure the state is updated one by one
  const updateGlobalLayout = useRecoilCallback(({ snapshot }) => {
    const queue: Queue[] = [];
    let isProcessing = false;

    const processQueue = async () => {
      if (isProcessing) {
        return;
      }
      isProcessing = true;

      while (queue.length > 0) {
        const item = queue.shift();

        if (!item) {
          continue;
        }

        const { params, resolve } = item;
        const currentState = await snapshot.getPromise(layoutState);
        const newState = {
          ...recoilState,
          ...params,
        };

        if (!isEqual(currentState, newState)) {
          setRecoilState(newState);
        }

        resolve();
      }
      isProcessing = false;
    };

    return (params: Params) =>
      new Promise<void>(resolve => {
        queue.push({ params, resolve });
        processQueue();
      });
  });

  return {
    ...recoilState,
    setGlobalLayout: updateGlobalLayout,
  };
};

export default useGlobalLayout;
