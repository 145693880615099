import styled from '@emotion/styled';
import { TermsText as PrivacyText, TermsTitle as PrivacyTitle } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import TopPageFooter from '../TopPage/Footer';

export { PrivacyText, PrivacyTitle };

export const Wrapper = styled.section({
  boxShadow: THEME.box.shadows.outer,
  width: '100%',
  padding: 48,
  backgroundColor: THEME.colors.white,
  borderRadius: 3,
  boxSizing: 'border-box',

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    borderRadius: 0,
    padding: '24px 24px 16px',
  },
});

export const Anchor = styled.a({
  display: 'inline',
  color: '#179cd7',

  '&:hover': {
    textDecoration: 'underline',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      textDecoration: 'none',
    },
  },
});

export const SubTitle = styled(PrivacyText)({
  color: THEME.font.colors.gray.main,
  paddingBottom: 40,
  textAlign: 'center',
});

export const SubTitleWithoutContent = styled.h3({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.heading,
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: 1.22,
  margin: '24px 0 16px',
  textTransform: 'uppercase',
});

export const ListWrapper = styled.ul({
  paddingLeft: 16,
});

export const ListItem = styled.li({
  listStyle: 'outside',
  marginBottom: 4,
});

export const Footer = styled(TopPageFooter)({
  paddingTop: 40,
});
