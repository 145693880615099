import { css } from '@emotion/react';
import * as React from 'react';
import { THEME } from '@src/libs/theme';

interface TermsTitleProps {
  className?: string;
  title: string;
}

const TermsTitle = (props: TermsTitleProps) => {
  const { className, title } = props;

  return (
    <h2 className={className} css={styles.title}>
      {title}
    </h2>
  );
};

const styles = {
  title: css({
    margin: '0 auto',
    marginBottom: 40,
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 'normal',
    textAlign: 'center',
    color: THEME.font.colors.black.main,
    maxWidth: 500,
  }),
};

export default TermsTitle;
