import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ErrorMessage } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import RegionSelector from '@src/components/organisms/RegionSelector';
import { Label, SelectV2, TextFormV2, YearMonthDayPickerV2 } from '@src/components/shared';
import { GENDERS } from '@src/libs/constant';
import {
  useAuthData,
  useAllAvailableCountries,
  useAllCountries,
  useInfluencerCategories,
  useTranslateOptions,
} from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { Genders } from '@src/__generated__/globalTypes';

export interface AccountInformation {
  brandName: string | null;
  categoryIds: number[];
  countryId: string;
  dateOfBirth: string;
  gender: Genders;
  introduce: string;
  name: string;
  regionId: string | null;
}

const CreatorAccount = () => {
  const { isAvailableCountry } = useAuthData();
  const { t } = useTranslation();
  const { influencerCategories } = useInfluencerCategories();
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<AccountInformation>();
  const [
    // brandName,
    categoryIds,
    countryId,
    dateOfBirth,
    gender,
    regionId,
  ] = watch([
    // 'brandName',
    'categoryIds',
    'countryId',
    'dateOfBirth',
    'gender',
    'regionId',
  ]);
  const isRegionRequired = countryId !== 'US' && isAvailableCountry;
  const { allTranslatedCountries: translatedCountries } = isRegionRequired
    ? useAllAvailableCountries()
    : useAllCountries();
  const translatedCategories = useTranslateOptions(influencerCategories);
  const translatedGenders = useTranslateOptions(GENDERS);

  return (
    <div css={styles.container}>
      <div css={styles.title}>{t('Title.Creator Account')}</div>
      <div css={styles.description}>{t('Annotation.Edit your information and set notifications to receive')}</div>
      <div css={styles.contentContainer}>
        <div>
          <TextFormV2
            error={!!errors.name}
            isRequired
            title="Creator Name"
            placeholder="Creator Name"
            {...register('name')}
          />
          <ErrorMessage message={errors.name?.message} />
        </div>

        {/* <div>
          <TextForm
            error={!!errors.brand}
            isRequired
            title="Brand Name"
            placeholder="Brand Name"
            {...register('brandName')}
          />
          <ErrorMessage message={errors.brandName?.message} />
        </div> */}

        <div>
          <SelectV2
            error={!!errors.categoryIds}
            isRequired
            multiple
            options={translatedCategories}
            title="Categories"
            value={categoryIds.map(id => id.toString()) || []}
            onChange={value =>
              setValue(
                'categoryIds',
                value.map(val => Number(val))
              )
            }
          />
          <ErrorMessage message={errors.categoryIds?.message} />
        </div>

        <div>
          <Label isRequired title="Selector.Date of Birth" />
          <YearMonthDayPickerV2
            format="yyyy-MM-dd"
            error={!!errors.dateOfBirth}
            maxDate={new Date()}
            value={dateOfBirth || ''}
            onChange={value => setValue('dateOfBirth', value as string)}
          />
          <ErrorMessage message={errors.dateOfBirth?.message} />
        </div>

        <div>
          <SelectV2
            disabled
            error={!!errors.countryId}
            isRequired
            options={translatedCountries}
            title="Country"
            value={countryId || ''}
            onChange={val => setValue('countryId', val as string)}
          />
          <ErrorMessage message={errors.countryId?.message} />
        </div>

        <div>
          <RegionSelector
            countryId={countryId}
            css={styles.regionSelector}
            error={!!errors.regionId}
            isRequired={isRegionRequired}
            title="Region"
            value={regionId || ''}
            onChange={value => setValue('regionId', value)}
          />
          <ErrorMessage message={errors.regionId?.message} />
        </div>

        <div>
          <SelectV2
            disabled
            error={!!errors.gender}
            isRequired
            options={translatedGenders}
            title="Gender"
            value={gender || ''}
            onChange={value => setValue('gender', value as Genders)}
          />
          <ErrorMessage message={errors.gender?.message} />
        </div>

        <ThemeButton
          css={{ borderRadius: 9, height: 48 }}
          customPalette={mainBlack}
          disabled={isSubmitting}
          text="Save"
          type="submit"
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 24px',
    },
  }),
  contentContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 16px',
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,
  }),
  regionSelector: css({
    '& > div:nth-of-type(2)': {
      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        borderRadius: 9,
        display: 'flex',
        minHeight: 46,

        '& > div': {
          fontSize: THEME.font.sizes.subHeading,
        },

        '& > svg': {
          bottom: 0,
          margin: 'auto 0',
        },
      },

      '& > div:nth-of-type(2) > div > div': {
        fontSize: THEME.font.sizes.subHeading,
      },
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 8,
  }),
};

export default CreatorAccount;
